export const englishEnums = {
    programTypeEnum: [
        { label: "Standard Service", value: "1:Standard Service" },
        { label: "Atlantic Immigration Program (AIP)", value: "2:Atlantic Immigration Program (AIP)" },
        { label: "Visible Minority Newcomer Women Pilot (VMNW)", value: "3:Visible Minority Newcomer Women Pilot (VMNW)" },
        { label: "Case Management for GAR and Other Vulnerable Newcomers", value: "4:Case Management for GAR and Other Vulnerable Newcomers" },
        { label: "Settlement Workers in Schools (SWIS)", value: "5:Settlement Workers in Schools (SWIS)" },
        { label: "Library Settlement Partnership (LSP)", value: "6:Library Settlement Partnership (LSP)" },
        { label: "Language and Employment", value: "7:Language and Employment" },
        { label: "Language Training-Francophone integration pathway/PALO", value: "8:Language Training-Francophone integration pathway/PALO" },
        { label: "Case Management Level 2", value: "10:Case Management Level 2" },
        { label: "Case Management Level 3", value: "11:Case Management Level 3" },
        { label: "Case Management Level 4", value: "12:Case Management Level 4" }
    ],
    languageEnum: [
        { label: "English", value: "1:English" },
        { label: "French", value: "2:French" },
        { label: "Ada", value: "3:Ada" },
        { label: "Affar", value: "4:Affar" },
        { label: "Afrikaans", value: "6:Afrikaans" },
        { label: "Aka", value: "7:Aka" },
        { label: "Akan", value: "8:Akan" },
        { label: "Aklanon", value: "9:Aklanon" },
        { label: "Akra", value: "10:Akra" },
        { label: "Albanian", value: "11:Albanian" },
        { label: "American Sign Language (ASL)", value: "250:American Sign Language (ASL)" },
        { label: "Amharic", value: "12:Amharic" },
        { label: "Arabic", value: "13:Arabic" },
        { label: "Aran", value: "14:Aran" },
        { label: "Armenian", value: "15:Armenian" },
        { label: "Ashanti", value: "16:Ashanti" },
        { label: "Assyrian", value: "17:Assyrian" },
        { label: "Azeri", value: "18:Azeri" },
        { label: "Bambara", value: "19:Bambara" },
        { label: "Bantu", value: "20:Bantu" },
        { label: "Baule", value: "21:Baule" },
        { label: "Belen", value: "22:Belen" },
        { label: "Bemba", value: "23:Bemba" },
        { label: "Bengali", value: "24:Bengali" },
        { label: "Beni", value: "25:Beni" },
        { label: "Benin", value: "26:Benin" },
        { label: "Berber", value: "27:Berber" },
        { label: "Bicol", value: "28:Bicol" },
        { label: "Bijaiya", value: "29:Bijaiya" },
        { label: "Bini", value: "30:Bini" },
        { label: "Bisaya", value: "31:Bisaya" },
        { label: "Bissa", value: "32:Bissa" },
        { label: "Bontok", value: "33:Bontok" },
        { label: "Breton", value: "34:Breton" },
        { label: "Bulgarian", value: "35:Bulgarian" },
        { label: "Burmese", value: "36:Burmese" },
        { label: "Busan", value: "37:Busan" },
        { label: "Busango", value: "38:Busango" },
        { label: "Cantonese", value: "39:Cantonese" },
        { label: "Catalan", value: "40:Catalan" },
        { label: "Cebuano", value: "41:Cebuano" },
        { label: "Chakma", value: "42:Chakma" },
        { label: "Chaldean", value: "43:Chaldean" },
        { label: "Chaocho", value: "44:Chaocho" },
        { label: "Chavacano", value: "45:Chavacano" },
        { label: "Chichewa", value: "46:Chichewa" },
        { label: "Chinese - Simplified", value: "252:Chinese - Simplified" },
        { label: "Chinese - Traditional", value: "253:Chinese - Traditional" },
        { label: "Chiuchow", value: "48:Chiuchow" },
        { label: "Chiyao", value: "49:Chiyao" },
        { label: "Chowchau", value: "50:Chowchau" },
        { label: "Concani", value: "51:Concani" },
        { label: "Creole", value: "52:Creole" },
        { label: "Croatian", value: "53:Croatian" },
        { label: "Czech", value: "54:Czech" },
        { label: "Danish", value: "55:Danish" },
        { label: "Dari", value: "56:Dari" },
        { label: "Dioula", value: "58:Dioula" },
        { label: "Dutch", value: "59:Dutch" },
        { label: "Dzongkha", value: "255:Dzongkha" },
        { label: "Edo", value: "60:Edo" },
        { label: "Efik", value: "61:Efik" },
        { label: "Estonian", value: "62:Estonian" },
        { label: "Ewe", value: "63:Ewe" },
        { label: "Fang", value: "64:Fang" },
        { label: "Fanti", value: "65:Fanti" },
        { label: "Farsi (Persian)", value: "66:Farsi (Persian)" },
        { label: "Finnish", value: "67:Finnish" },
        { label: "Flemish", value: "68:Flemish" },
        { label: "Foochow", value: "69:Foochow" },
        { label: "Fouki", value: "70:Fouki" },
        { label: "Foullah", value: "71:Foullah" },
        { label: "Fukinese", value: "72:Fukinese" },
        { label: "Fulani", value: "73:Fulani" },
        { label: "Ga", value: "74:Ga" },
        { label: "Gaelic", value: "75:Gaelic" },
        { label: "German", value: "77:German" },
        { label: "Greek", value: "78:Greek" },
        { label: "Guerze", value: "79:Guerze" },
        { label: "Gujarati", value: "80:Gujarati" },
        { label: "Hainam", value: "81:Hainam" },
        { label: "Hakha Chin", value: "256:Hakha Chin" },
        { label: "Hakka", value: "82:Hakka" },
        { label: "Harara", value: "83:Harara" },
        { label: "Harary", value: "84:Harary" },
        { label: "Hargar", value: "85:Hargar" },
        { label: "Hassanya", value: "86:Hassanya" },
        { label: "Hausa", value: "87:Hausa" },
        { label: "Hebrew", value: "88:Hebrew" },
        { label: "Hiligaynon", value: "89:Hiligaynon" },
        { label: "Hindi", value: "90:Hindi" },
        { label: "Hindko", value: "91:Hindko" },
        { label: "Hokkin", value: "92:Hokkin" },
        { label: "Hungarian", value: "93:Hungarian" },
        { label: "Ibibio", value: "94:Ibibio" },
        { label: "Ibo", value: "95:Ibo" },
        { label: "Icelandic", value: "96:Icelandic" },
        { label: "Igorot", value: "97:Igorot" },
        { label: "Ilican", value: "99:Ilican" },
        { label: "Ilongo", value: "98:Ilongo" },
        { label: "Indonesian", value: "100:Indonesian" },
        { label: "Ishan", value: "101:Ishan" },
        { label: "Italian", value: "102:Italian" },
        { label: "Izi", value: "103:Izi" },
        { label: "Japanese", value: "104:Japanese" },
        { label: "Javanese", value: "105:Javanese" },
        { label: "Jolay", value: "106:Jolay" },
        { label: "Kacchi", value: "107:Kacchi" },
        { label: "Kakwa", value: "108:Kakwa" },
        { label: "Kanarese", value: "109:Kanarese" },
        { label: "Kandahari", value: "110:Kandahari" },
        { label: "Kankani", value: "111:Kankani" },
        { label: "Karen", value: "249:Karen" },
        { label: "Kashmiri", value: "112:Kashmiri" },
        { label: "Khmer", value: "113:Khmer" },
        { label: "Kihavu", value: "114:Kihavu" },
        { label: "Kirundi", value: "115:Kirundi" },
        { label: "Kiswahili", value: "116:Kiswahili" },
        { label: "Korean", value: "117:Korean" },
        { label: "Kpelle", value: "118:Kpelle" },
        { label: "Krio", value: "119:Krio" },
        { label: "Kumaoni", value: "120:Kumaoni" },
        { label: "Kurdish", value: "121:Kurdish" },
        { label: "Lahu", value: "122:Lahu" },
        { label: "Lao", value: "123:Lao" },
        { label: "Latvian", value: "124:Latvian" },
        { label: "Lingala", value: "125:Lingala" },
        { label: "Lithuanian", value: "126:Lithuanian" },
        { label: "Lomwe", value: "127:Lomwe" },
        { label: "Luvale", value: "128:Luvale" },
        { label: "Macedonian", value: "129:Macedonian" },
        { label: "Malgasy", value: "130:Malgasy" },
        { label: "Malay", value: "131:Malay" },
        { label: "Malayalam", value: "132:Malayalam" },
        { label: "Malinke", value: "133:Malinke" },
        { label: "Mandarin", value: "134:Mandarin" },
        { label: "Mandinka", value: "135:Mandinka" },
        { label: "Maninka", value: "136:Maninka" },
        { label: "Manu", value: "137:Manu" },
        { label: "Marathi", value: "138:Marathi" },
        { label: "Mende", value: "139:Mende" },
        { label: "Minankobah", value: "140:Minankobah" },
        { label: "Mongolian", value: "141:Mongolian" },
        { label: "Ndebele", value: "142:Ndebele" },
        { label: "Nepali", value: "143:Nepali" },
        { label: "Nuer", value: "144:Nuer" },
        { label: "Nyanja", value: "145:Nyanja" },
        { label: "Oriya", value: "146:Oriya" },
        { label: "Oromo", value: "147:Oromo" },
        { label: "Pashto", value: "148:Pashto" },
        { label: "Pidgin", value: "149:Pidgin" },
        { label: "Polish", value: "150:Polish" },
        { label: "Portuguese", value: "151:Portuguese" },
        { label: "Punjabi", value: "152:Punjabi" },
        { label: "Romanian", value: "153:Romanian" },
        { label: "Russian", value: "154:Russian" },
        { label: "Sango", value: "155:Sango" },
        { label: "Sanskrit", value: "156:Sanskrit" },
        { label: "Serbo-Croatian", value: "157:Serbo-Croatian" },
        { label: "Setswana", value: "158:Setswana" },
        { label: "Shanghainese", value: "159:Shanghainese" },
        { label: "Shona", value: "160:Shona" },
        { label: "Siksika", value: "257:Siksika" },
        { label: "Sindhi", value: "161:Sindhi" },
        { label: "Sinhala", value: "162:Sinhala" },
        { label: "Slovak", value: "163:Slovak" },
        { label: "Slovenian", value: "164:Slovenian" },
        { label: "Somali", value: "165:Somali" },
        { label: "Sonsur", value: "166:Sonsur" },
        { label: "Spanish", value: "167:Spanish" },
        { label: "Sudanese", value: "168:Sudanese" },
        { label: "Susu", value: "169:Susu" },
        { label: "Swahili", value: "170:Swahili" },
        { label: "Swati", value: "171:Swati" },
        { label: "Swedish", value: "172:Swedish" },
        { label: "Tagalog", value: "173:Tagalog" },
        { label: "Tamil", value: "174:Tamil" },
        { label: "Telugu", value: "175:Telugu" },
        { label: "Tigrinya", value: "176:Tigrinya" },
        { label: "Tonga", value: "177:Tonga" },
        { label: "Tshiluba", value: "178:Tshiluba" },
        { label: "Turkish", value: "179:Turkish" },
        { label: "Turkoman", value: "180:Turkoman" },
        { label: "Ukrainian", value: "181:Ukrainian" },
        { label: "Urdu", value: "182:Urdu" },
        { label: "Uzbek", value: "183:Uzbek" },
        { label: "Vietnamese", value: "184:Vietnamese" },
        { label: "Wolof", value: "185:Wolof" },
        { label: "Xhosa", value: "186:Xhosa" },
        { label: "Yao", value: "187:Yao" },
        { label: "Yapese", value: "188:Yapese" },
        { label: "Yiddish", value: "189:Yiddish" },
        { label: "Yoruba", value: "190:Yoruba" },
        { label: "Zhuang", value: "191:Zhuang" },
        { label: "Zulu", value: "192:Zulu" },
    ],

    clientValidationTypeEnum: [
        { label: "FOSS/GCMS Client ID", value: "1:FOSS/GCMS Client ID" },
        { label: "Temporary Resident or Minister's Permit Number", value: "2:Temporary Resident or Minister’s Permit Number" },
        { label: "IMM5292, IMM5509, IMM1000 Number", value: "3:IMM5292, IMM5509, IMM1000 Number" }
    ],
    officialLanguageEnum: [
        { label: "English", value: "1:English" },
        { label: "French", value: "2:French" },
        { label: "Unknown/No preference", value: "3:Unknown/No preference" },
    ],
    yesNoEnum: [
        { label: "Yes", value: "1:Yes" },
        { label: "No", value: "0:No" }
    ],
    institutionEnum: [
        { label: "Settlement service provider", value: "1:Settlement service provider" },
        { label: "Public library", value: "2:Public library" },
        { label: "Elementary school", value: "3:Elementary school" },
        { label: "Secondary school", value: "4:Secondary school" },
        { label: "Post-secondary institution", value: "5:Post-secondary institution" },
        { label: "Healthcare institution", value: "6:Healthcare institution" },
        { label: "Community centre/neighbourhood house", value: "7:Community centre/neighbourhood house" },
        { label: "Employment agency", value: "8:Employment agency" },
        { label: "The client's home", value: "9:The client’s home" },
        { label: "The client's place of employment", value: "10:The client’s place of employment" },
        { label: "A public space (shopping centre, etc.)", value: "11:A public space (shopping centre, etc.)" },
        { label: "Port of entry", value: "12:Port of entry" },
        { label: "Not for profit organization", value: "14:Not for profit organization" },
        { label: "SPO's representative's home", value: "16:SPO’s representative’s home" }
    ],
    assessmentReferralEnum: [
        { label: "Community centre / library", value: "1:Community centre / library" },
        { label: "Employer / co-worker", value: "2:Employer / co-worker" },
        { label: "Ethnic or religious organization", value: "3:Ethnic or religious organization" },
        { label: "Family / friends", value: "4:Family / friends" },
        { label: "In-Canada orientation session", value: "5:In-Canada orientation session" },
        { label: "Canadian government agency", value: "6:Canadian government agency" },
        { label: "Government publication / brochure / website", value: "7:Government publication / brochure / website" },
        { label: "Immigration consultant / lawyer", value: "8:Immigration consultant / lawyer" },
        { label: "Internal to my organization", value: "9:Internal to my organization" },
        { label: "Non-governmental newspaper / media / publication / brochure / Web site", value: "10:Non-governmental newspaper / media / publication / brochure / Web site" },
        { label: "Other settlement service provider", value: "11:Other settlement service provider" },
        { label: "School", value: "12:School" },
        { label: "Not referred", value: "13:Not referred" },
        { label: "Overseas orientation session (e.g. CIIP)", value: "14:Overseas orientation session (e.g. CIIP)" }
    ],
    skillsNeedReasonEnum: [
        { label: "Find employment", value: "1:Find employment" },
        { label: "Get an education", value: "2:Get an education" },
        { label: "Participate in Canadian society", value: "3:Participate in Canadian society" },
        { label: "Acquire citizenship", value: "4:Acquire citizenship" }
    ],
    findEmploymentPeriodEnum: [
        { label: "Within one year", value: "1:Within one year" },
        { label: "After one year", value: "2:After one year" }
    ],
    canadianEmploymentExperienceEnum: [
        { label: "No", value: "1:No" },
        { label: "Yes, within Canada", value: "2:Yes, within Canada" },
        { label: "Yes, outside Canada", value: "3:Yes, outside Canada" },
        { label: "Yes, both within and outside Canada", value: "4:Yes, both within and outside Canada" }
    ],
    nocGroupsEnum: [
        { label: "O - Management occupations", value: "1:O - Management occupations" },
        { label: "A - Professional", value: "2:A - Professional" },
        { label: "B - Skilled and technical", value: "3:B - Skilled and technical" },
        { label: "C - Intermediate and clerical", value: "4:C - Intermediate and clerical" },
        { label: "D - Elemental and labourers", value: "5:D - Elemental and labourers" },
        { label: "Unknown/not sure", value: "6:Unknown/not sure" }
    ],
    yesNoUnknownEnum: [
        { label: "Yes", value: "1:Yes" },
        { label: "No", value: "2:No" },
        { label: "Unknown/Not sure", value: "3:Unknown/Not sure" }
    ],
    childMindingAgeEnum: [
        { label: "Infant (6-18 months)", value: "1:Infant (6-18 months)" },
        { label: "Toddler (19-35 months)", value: "2:Toddler (19-35 months)" },
        { label: "Pre-school (36 months - 6 years)", value: "3:Pre-school (36 months - 6 years)" },
        { label: "School age (more than 6 years)", value: "4:School age (more than 6 years)" }
    ],
    childMindingTypeEnum: [
        { label: "Long term", value: "1:Long term" },
        { label: "Short term", value: "2:Short term" }
    ],
    yesNoNotApplicableEnum: [
        { label: "Yes", value: "1:Yes" },
        { label: "No", value: "2:No" },
        { label: "Not applicable", value: "3:Not applicable" }
    ],
    assessmentUpdateReasonEnum: [
        { label: "Correct error", value: "1:Correct error" },
        { label: "Amend record", value: "2:Amend record" }
    ],
    orientationServiceEnum: [
        { label: "One-on-one orientation", value: "1:One-on-one orientation" },
        { label: "Family orientation", value: "2:Family orientation" },
        { label: "Group orientation", value: "3:Group orientation" },
    ],
    durationServiceEnum: [
        { label: "5 minutes or less", value: "1:5 minutes or less" },
        { label: "6 - 30 minutes", value: "2:6 - 30 minutes" },
        { label: "30 - 60 minutes", value: "3:30 - 60 minutes" },
        { label: "1 to 2 hours", value: "5:1 to 2 hours" },
        { label: "2 to 3 hours", value: "6:2 to 3 hours" },
        { label: "3 to 4 hours", value: "7:3 to 4 hours" },
        { label: "4 to 5 hours", value: "8:4 to 5 hours" },
        { label: "5 to 6 hours", value: "9:5 to 6 hours" },
        { label: "6 to 7 hours", value: "10:6 to 7 hours" },
        { label: "7 to 8 hours", value: "11:7 to 8 hours" },
        { label: "8 to 9 hours", value: "12:8 to 9 hours" },
        { label: "9 to 10 hours", value: "13:9 to 10 hours" },
        { label: "10 to 11 hours", value: "14:10 to 11 hours" },
        { label: "11 to 12 hours", value: "15:11 to 12 hours" },
        { label: "12 to 13 hours", value: "16:12 to 13 hours" },
        { label: "13 to 14 hours", value: "17:13 to 14 hours" },
        { label: "14 to 15 hours", value: "18:14 to 15 hours" },
        { label: "15 to 16 hours", value: "19:15 to 16 hours" },
        { label: "16 to 17 hours", value: "20:16 to 17 hours" },
        { label: "17 to 18 hours", value: "21:17 to 18 hours" },
        { label: "18 to 19 hours", value: "22:18 to 19 hours" },
        { label: "19 to 20 hours", value: "23:19 to 20 hours" },
        { label: "20 to 21 hours", value: "24:20 to 21 hours" },
        { label: "21 to 22 hours", value: "25:21 to 22 hours" },
        { label: "22 to 23 hours", value: "26:22 to 23 hours" },
        { label: "23 to 24 hours", value: "27:23 to 24 hours" },
        { label: "24 to 25 hours", value: "28:24 to 25 hours" },
        { label: "25 to 26 hours", value: "29:25 to 26 hours" },
        { label: "26 to 27 hours", value: "30:26 to 27 hours" },
        { label: "27 to 28 hours", value: "31:27 to 28 hours" },
        { label: "28 to 29 hours", value: "32:28 to 29 hours" },
        { label: "29 to 30 hours", value: "33:29 to 30 hours" },
        { label: "Over 30 hours", value: "34:Over 30 hours" },
    ],
    groupSizeEnum: [
        { label: "Less than 10 people", value: "1:Less than 10 people" },
        { label: "10 - 20 people", value: "2:10 - 20 people" },
        { label: "More than 20 people", value: "3:More than 20 people" },
    ],
    communityActivityEnum: [
        { label: "Community-based group events and activities", value: "1:Community-based group events and activities" },
        { label: "Targeted Matching and Networking", value: "2:Targeted Matching and Networking" }
    ],
    communityLeaveReasonEnum: [
        { label: "Client felt the service was not meeting current needs", value: "1:Client felt the service was not meeting current needs" },
        { label: "Found employment", value: "2:Found employment" },
        { label: "Lack of support services", value: "3:Lack of support services" },
        { label: "Moved/unable to contact client", value: "4:Moved/unable to contact client" },
        { label: "Obtained citizenship", value: "5:Obtained citizenship" },
        { label: "Medical reason", value: "6:Medical reason" },
        { label: "Personal reason", value: "7:Personal reason" },
        { label: "Parental leave/caring for family members", value: "8:Parental leave/caring for family members" },
        { label: "Student left school", value: "9:Student left school" },
        { label: "To attend school", value: "10:To attend school" },
        { label: "To receive another settlement service", value: "11:To receive another settlement service" },
        { label: "Reason unknown", value: "12:Reason unknown" },
        { label: "Intervention ended by mentor", value: "13:Intervention ended by mentor" },
        { label: "Intervention ended by third party/volunteer", value: "14:Intervention ended by third party/volunteer" }
    ],
    communityServiceBulkUploadEnum: [
        { label: "Community-based group events and activities: Group session (e.g. workshop, presentation)", value: "1:Community-based group events and activities: Group session (e.g. workshop, presentation)" },
        { label: "Targeted Matching and Networking: One-on-one session (e.g. mentoring)", value: "2:Targeted Matching and Networking: One-on-one session (e.g. mentoring)" },
        { label: "Targeted Matching and Networking: Group session (e.g. conversation circles)", value: "3:Targeted Matching and Networking: Group session (e.g. conversation circles)" }
    ],
    communityBasedCommunityServiceBulkUploadEnum: [
        { label: "Group session (e.g. workshop, presentation)", value: "1:Community-based group events and activities: Group session (e.g. workshop, presentation)" },
    ],
    targetedMatchingCommunityServiceBulkUploadEnum: [
        { label: "One-on-one session (e.g. mentoring)", value: "2:Targeted Matching and Networking: One-on-one session (e.g. mentoring)" },
        { label: "Group session (e.g. conversation circles)", value: "3:Targeted Matching and Networking: Group session (e.g. conversation circles)" }
    ],
    communityTopicBulkUploadEnum: [
        { label: "Access to local community services", value: "2:Access to local community services" },
        { label: "Connecting with other newcomers", value: "3:Connecting with other newcomers" },
        { label: "Getting involved in the community", value: "6:Getting involved in the community" },
        { label: "Targeted Matching and Networking: Accessing the labour market", value: "7:Targeted Matching and Networking: Accessing the labour market" },
        { label: "Connecting with settled immigrants or long-time Canadians", value: "8:Connecting with settled immigrants or long-time Canadians" },
        { label: "Community-based group events and activities: Increasing knowledge of Canadian society/context", value: "9:Community-based group events and activities: Increasing knowledge of Canadian society/context" },
        { label: "Targeted Matching and Networking: Informal problem solving", value: "10:Targeted Matching and Networking: Informal problem solving" },
        { label: "Inter-cultural sensitivity and understanding (e.g. interpersonal dynamics)", value: "11:Inter-cultural sensitivity and understanding (e.g. interpersonal dynamics)" },
        { label: "Targeted Matching and Networking: Language learning", value: "12:Targeted Matching and Networking: Language learning" },
        { label: "Mental health and well-being", value: "13:Mental health and well-being" }
    ],
    targetedMatchingcommunityTopicBulkUploadEnum: [
        { label: "Access to local community services", value: "2:Access to local community services" },
        { label: "Accessing the labour market", value: "7:Targeted Matching and Networking: Accessing the labour market" },
        { label: "Connecting with other newcomers", value: "3:Connecting with other newcomers" },
        { label: "Connecting with settled immigrants or long-time Canadians", value: "8:Connecting with settled immigrants or long-time Canadians" },
        { label: "Getting involved in the community", value: "6:Getting involved in the community" },
        { label: "Informal problem solving", value: "10:Targeted Matching and Networking: Informal problem solving" },
        { label: "Inter-cultural sensitivity and understanding (e.g. interpersonal dynamics)", value: "11:Inter-cultural sensitivity and understanding (e.g. interpersonal dynamics)" },
        { label: "Mental health and well-being", value: "13:Mental health and well-being" },
        { label: "Language learning", value: "12:Targeted Matching and Networking: Language learning" },
    ],
    communityBasedcommunityTopicBulkUploadEnum: [
        { label: "Access to local community services", value: "2:Access to local community services" },
        { label: "Connecting with other newcomers", value: "3:Connecting with other newcomers" },
        { label: "Connecting with settled immigrants or long-time Canadians", value: "8:Connecting with settled immigrants or long-time Canadians" },
        { label: "Getting involved in the community", value: "6:Getting involved in the community" },
        { label: "Increasing knowledge of Canadian society/context", value: "9:Community-based group events and activities: Increasing knowledge of Canadian society/context" },
        { label: "Inter-cultural sensitivity and understanding (e.g. interpersonal dynamics)", value: "11:Inter-cultural sensitivity and understanding (e.g. interpersonal dynamics)" },
        { label: "Mental health and well-being", value: "13:Mental health and well-being" }
    ],
    serviceStatusEnum: [
        { label: "Service ended early (i.e. client ended participation)", value: "2:Service ended early (i.e. client ended participation)" },
        { label: "Ongoing", value: "3:Ongoing" },
        { label: "Completed", value: "1:Completed" },
    ],
    eventTypeEnum: [
        { label: "Events/visits pertaining to culture or history", value: "1:Events/visits pertaining to culture or history" },
        { label: "Field trip connecting newcomer to community resources or local services", value: "2:Field trip connecting newcomer to community resources or local services" },
        { label: "Sports/recreation event", value: "3:Sports/recreation event" },
        { label: "Neighbourhood day", value: "4:Neighbourhood day" },
        { label: "Other community event", value: "5:Other community event" }
    ],
    programTypeBulkEnum: [
        { label: "Standard Service", value: "1:Standard Service" },
        { label: "Case Management for GAR and Other Vulnerable Newcomers", value: "4:Case Management for GAR and Other Vulnerable Newcomers" },
        { label: "Language Training - Francophone Integration Pathway/PALO", value: "8:Language Training-Francophone integration pathway/PALO" },
        { label: "Case Management Level 2", value: "10:Case Management Level 2" },
        { label: "Case Management Level 3", value: "11:Case Management Level 3" },
        { label: "Case Management Level 4", value: "12:Case Management Level 4" },
        { label: "Language and Employment", value: "7:Language and Employment" },
        { label: "Library Settlement Partnership (LSP)", value: "6:Library Settlement Partnership (LSP)" },
        { label: "Settlement Workers in Schools (SWIS)", value: "5:Settlement Workers in Schools (SWIS)" },
        { label: "Visible Minority Newcomer Women Pilot (VMNW)", value: "3:Visible Minority Newcomer Women Pilot (VMNW)" }
    ],
    serviceTypeEnum: [
        { label: "Conversation circle", value: "1:Conversation circle" },
        { label: "Targeted Matching (Canada Connects)", value: "2:Targeted Matching (Canada Connects)" },
        { label: "Networking activity with other newcomers or Canadian citizens", value: "3:Networking activity with other newcomers or Canadian citizens" },
        { label: "Youth leadership project", value: "4:Youth leadership project" },
        { label: "Other regular group activities to address ongoing needs or interests", value: "5:Other regular group activities to address ongoing needs or interests" }
    ],
    employmentStatusV2Enum: [
        { label: "Employed part time - less than 30 hours at main or only job", value: "1:Employed part time - less than 30 hours at main or only job" },
        { label: "Employed full time - 30 hours or more at main or only job", value: "2:Employed full time - 30 hours or more at main or only job" },
        { label: "Self-employed", value: "3:Self-employed" },
        { label: "Entrepreneur/business owner", value: "4:Entrepreneur/business owner" },
        { label: "Not employed/not applicable", value: "5:Not employed/not applicable" },
    ],
    intendedEmploymentStatusEnum: [
        { label: "Employed", value: "1:Employed" },
        { label: "Self-employed", value: "2:Self-employed" },
        { label: "Entrepreneur/business owner", value: "3:Entrepreneur/business owner" },
    ],
    nocListEnum: [
        { label: "Senior management occupations", value: "1:00 Senior management occupations" },
        { label: "Specialized middle management occupations", value: "27:01-05 Specialized middle management occupations" },
        { label: "Middle management occupations in retail and wholesale trade and customer service", value: "28:06 Middle management occupations in retail and wholesale trade and customer serv" },
        { label: "Middle management occupations in trades, transportation, production, and utilities", value: "29:07-09 Middle management occupations in trades, transportation, production and ut" },
        { label: "Professional occupations in business and finance", value: "3:11 Professional occupations in business and finance" },
        { label: "Administrative and financial supervisors and administrative occupations", value: "4:12 Administrative and financial supervisors and administrative occupations" },
        { label: "Finance, insurance, and related business administrative occupations", value: "30:13 Finance, insurance and related business administrative occupations" },
        { label: "Office support occupations", value: "5:14 Office support occupations" },
        { label: "Distribution, tracking, and scheduling coordination occupations", value: "31:15 Distribution, tracking and scheduling co-ordination occupations" },
        { label: "Professional occupations in natural and applied sciences", value: "6:21 Professional occupations in natural and applied sciences" },
        { label: "Technical occupations related to natural and applied sciences", value: "7:22 Technical occupations related to natural and applied sciences" },
        { label: "Professional occupations in nursing", value: "32:30 Professional occupations in nursing" },
        { label: "Professional occupations in health (except nursing)", value: "8:31 Professional occupations in health (except nursing)" },
        { label: "Technical occupations in health", value: "9:32 Technical occupations in health" },
        { label: "Assisting occupations in support of health services", value: "33:34 Assisting occupations in support of health services" },
        { label: "Professional occupations in education services", value: "34:40 Professional occupations in education services" },
        { label: "Professional occupations in law and social, community, and government services", value: "11:41 Professional occupations in law and social, community and government services" },
        { label: "Paraprofessional occupations in legal, social, community, and education services", value: "12:42 Paraprofessional occupations in legal, social, community and education servic" },
        { label: "Occupations in front-line public protection services", value: "35:43 Occupations in front-line public protection services" },
        { label: "Care providers and educational, legal, and public protection support occupations", value: "36:44 Care providers and educational, legal and public protection support occupatio" },
        { label: "Professional occupations in art and culture", value: "13:51 Professional occupations in art and culture" },
        { label: "Technical occupations in art, culture, recreation, and sport", value: "14:52 Technical occupations in art, culture, recreation and sport" },
        { label: "Retail sales supervisors and specialized sales occupations", value: "15:62 Retail sales supervisors and specialized sales occupations" },
        { label: "Service supervisors and specialized service occupations", value: "37:63 Service supervisors and specialized service occupations" },
        { label: "Sales representatives and salespersons - wholesale and retail trade", value: "16:64 Sales representatives and salespersons - wholesale and retail trade" },
        { label: "Service representatives and other customer and personal services occupations", value: "38:65 Service representatives and other customer and personal services occupations" },
        { label: "Sales support occupations", value: "17:66 Sales support occupations" },
        { label: "Service support and other service occupations, n.e.c.", value: "39:67 Service support and other service occupations, n.e.c." },
        { label: "Industrial, electrical, and construction trades", value: "40:72 Industrial, electrical and construction trades" },
        { label: "Maintenance and equipment operation trades", value: "41:73 Maintenance and equipment operation trades" },
        { label: "Other installers, repairers, and servicers and material handlers", value: "19:74 Other installers, repairers and servicers and material handlers" },
        { label: "Transport and heavy equipment operation and related maintenance occupations", value: "42:75 Transport and heavy equipment operation and related maintenance occupations" },
        { label: "Trades helpers, construction laborers, and related occupations", value: "20:76 Trades helpers, construction labourers and related occupations" },
        { label: "Supervisors and technical occupations in natural resources, agricultural and related production", value: "21:82 Supervisors and technical occupations in natural resources, agricultural and " },
        { label: "Workers in natural resources, agriculture, and related production", value: "22:84 Workers in natural resources, agriculture and related production" },
        { label: "Harvesting, landscaping, and natural resources laborers", value: "23:86 Harvesting, landscaping and natural resources labourers" },
        { label: "Processing, manufacturing, and utilities supervisors and central control operators", value: "24:92 Processing, manufacturing and utilities supervisors and central control opera" },
        { label: "Processing and manufacturing machine operators and related production workers", value: "43:94 Processing and manufacturing machine operators and related production workers" },
        { label: "Assemblers in manufacturing", value: "44:95 Assemblers in manufacturing" },
        { label: "Laborers in processing, manufacturing, and utilities", value: "26:96 Labourers in processing, manufacturing and utilities" }
    ],
    interventionReceived: [
        { label: "Temporary Work Placement", value: "1:Temporary Work Placement" },
        { label: "Mentoring", value: "2:Mentoring" },
        { label: "Workshop", value: "7:Workshop" },
        { label: "Individual Employment Counselling", value: "4:Individual Employment Counselling" },
        { label: "Networking Opportunities", value: "5:Networking Opportunities" },
        { label: "Resume Screening and Matching", value: "6:Resume Screening and Matching" }
    ],
    interventionStatus: [
        { label: "Intervention ended early (i.e. client ended participation)", value: "2:Intervention ended early (i.e. client ended participation)" },
        { label: "Ongoing", value: "3:Ongoing" },
        { label: "Completed", value: "1:Completed" }
    ],
    interventionReason: [
        { label: "Found employment", value: "1:Found employment" },
        { label: "To attend school", value: "2:To attend school" },
        { label: "Personal reason (i.e. parental/family leave, medical, moved, unable to contact)", value: "3:Personal reason (i.e. parental/family leave, medical, moved, unable to contact)" },
        { label: "Lack of support services", value: "4:Lack of support services" },
        { label: "To receive another settlement service", value: "5:To receive another settlement service" },
        { label: "Client felt the intervention was not meeting their employment development needs", value: "6:Client felt the intervention was not meeting their employment development needs" },
        { label: "Obtained citizenship", value: "7:Obtained citizenship" },
        { label: "Intervention ended by third-party (i.e. mentor, employer, volunteer)", value: "8:Intervention ended by third-party (i.e. mentor, employer, volunteer)" },
        { label: "Reason unknown", value: "9:Reason unknown" }
    ],
    interventionTopic: [
        { label: "Labour Market Information", value: "1:Labour Market Information" },
        { label: "Job Search Skills", value: "2:Job Search Skills" },
        { label: "Workplace Health, Safety and Orientation Training", value: "3:Workplace Health, Safety and Orientation Training" },
        { label: "Essential Skills (focused-training)", value: "7:Essential Skills (focused-training)" },
        { label: "Preparation for Professional Licensure/Certification", value: "4:Preparation for Professional Licensure/Certification" },
        { label: "Preparation for Entrepreneurship", value: "5:Preparation for Entrepreneurship" },
        { label: "Preparation for non-Regulated Profession", value: "6:Preparation for non-Regulated Profession" }
    ],
    paymentType: [
        { label: "Paid", value: "1:Paid" },
        { label: "Unpaid", value: "2:Unpaid" }
    ],
    enterpriseSize: [
        { label: "Small-medium enterprise - fewer than 500 employees", value: "1:Small-medium enterprise - fewer than 500 employees" },
        { label: "Large enterprise - 500 employees or more", value: "2:Large enterprise - 500 employees or more" }
    ]
}
