import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Role, history } from "../../_helpers";
import jQuery from "jquery";
import { setPrivateKey, setPublicKey } from "../../redux-store/action";
import { Router, Route } from "react-router-dom";
import routes from "./routeArray";
import { PrivateRoute } from "../PrivateRoute";
import ReactLoaderSpinner from "react-loader-spinner";

// Lazy load components
const LoginPage = lazy(() => import("../../pages/LoginPage"));
const ForgotPasswordPage = lazy(() => import("../../pages/ForgotPasswordPage"));
const PayrollMasterPage = lazy(() =>
  import("../../pages/user/PayrollMasterPage")
);
const PayrollReportPage = lazy(() =>
  import("../../pages/user/PayrollReportPage")
);

window.jQuery = jQuery;

function mapStateToProps(state) {
  return {
    private_key: state.private_key,
    public_key: state.public_key,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPublicKey: (data) => {
      dispatch(setPublicKey(data));
    },
    setPrivateKey: (data) => {
      dispatch(setPrivateKey(data));
    },
  };
}

class RouterComponent extends Component {
  render() {
    const allowUserId = [
      "5ea76820e9eacf196c768d3b",
      "5efde9f884c79054a082c11c",
      "5ea74fade9eacf196c768d39",
      "607e407d5de8254bdcc19cc0",
      "607a02898bd9056dbc769438",
      "5e7e7d3292613f73e3ebd816",
      "61c127f4f663a42d18427507",
      "5e7e481492613f73e3ebd803",
      "65a942c3066ca2166b03e8c6",
      "636e836a64a85a1ed7c9e33a",
      "66fd99db7aadf961399eee63",
    ];

    return (
      <Router history={history} onUpdate={(a) => console.log(a)}>
        <Suspense
          fallback={
            <div id="loaderRoot">
              <div className="loaderPositionsStyle">
                <div className="loaderPositionsStyleSub">
                  <ReactLoaderSpinner
                    type="Rings"
                    color="#6F42C1"
                    height={80}
                    width={80}
                  />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/login" component={LoginPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          {routes.map(({ exact, path, role, Component }) => {
            return (
              <PrivateRoute
                key={path}
                exact={exact}
                path={path}
                roles={{ role }}
                component={Component}
              />
            );
          })}
          {allowUserId.includes(this.props.currentUser?.id) && (
            <>
              <PrivateRoute
                exact
                path="/payroll-master"
                roles={{ role: [Role.User, Role.Manager] }}
                component={PayrollMasterPage}
              />
              <PrivateRoute
                exact
                path="/payroll-report"
                roles={{ role: [Role.User, Role.Manager] }}
                component={PayrollReportPage}
              />
            </>
          )}
        </Suspense>
      </Router>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterComponent);
