import { languages } from "../_helpers/ICARS";
import { englishEnums } from "../_helpers/ICARS-english";
import {
  SET_USERDATA,
  IS_LOADING,
  SET_DEPARTMENTS,
  SET_SELECTED_FORM,
  SET_TAB_NAME,
  SET_CHILD_FORM,
  SET_SEAT_FORM,
  SET_LIST_FORM,
  SET_LEAD_FORM,
  SET_EDGE_FORM,
  SET_ES_FORM,
  SET_EWE_FORM,
  SET_PUBLIC_KEY,
  SET_PRIVATE_KEY,
  SET_CASE_NOTE,
  SET_EMPLOYER_CASE_NOTE,
  SET_EMPLOYER_FORM,
  SET_WIST_FORM,
  SET_CCT_FORM,
  SET_EPA_FORM,
  SET_CITIZENSHIP_FORM,
  SET_SURVEY_FORM,
  SET_ICARE_LANGUAGE,
  SET_ICARE_LANGUAGE_ENUMS,
  SET_SELECTED_INTAKE_FOR_ICARE,
  SET_ICARE_FORM,
  SET_SELECTED_COMMUNITY_CONNECTION_INTAKE,
  SET_CMCN_GROUPS,
  SET_CMCN_GROUP_DATA,
  SET_ICARE_LOADER,
} from "./action";

const initialState = {
  user: {},
  isLoading: true,
  allDepartments: [],
  selectedForm: {},
  childForm: {},
  seatForm: {},
  listForm: {},
  leadForm: {},
  edgeForm: {},
  esForm: {},
  employerForm: {},
  wistForm: {},
  cctForm: {},
  epaForm: {},
  citizenshipForm: {},
  eweForm: {},
  surveyForm: {},
  tabName: false,
  public_key: false,
  private_key: false,
  caseNote: [],
  EmployercaseNote: [],
  selectedLanguage: languages.ENGLISH,
  selectedLanguageEnums: englishEnums,
  selectedIntakeForIcare: {},
  selectedIcareForm: {},
  communityConnectionIntakeForms: [],
  selectedCMCNGroup: {},
  icareCMCNGroups: [],
  showIcareLoader: false
};

export const reducer = (state = initialState, action) => {
  console.log('onSetIcareLanguageEnums action: ', action.payload);
  switch (action.type) {
    case SET_USERDATA:
      return { ...state, user: action.payload };
    case SET_DEPARTMENTS:
      return { ...state, allDepartments: action.payload };
    case IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_SELECTED_FORM:
      return { ...state, selectedForm: action.payload };
    case SET_CHILD_FORM:
      return { ...state, childForm: action.payload };
    case SET_SEAT_FORM:
      return { ...state, seatForm: action.payload };
    case SET_LIST_FORM:
      return { ...state, listForm: action.payload };
    case SET_LEAD_FORM:
      return { ...state, leadForm: action.payload };
    case SET_EDGE_FORM:
      return { ...state, edgeForm: action.payload };
    case SET_ES_FORM:
      return { ...state, esForm: action.payload };
    case SET_EWE_FORM:
      return { ...state, eweForm: action.payload };
    case SET_TAB_NAME:
      return { ...state, tabName: action.payload };
    case SET_CASE_NOTE:
      return { ...state, caseNote: action.payload };
    case SET_EMPLOYER_CASE_NOTE:
      return { ...state, EmployercaseNote: action.payload };
    case SET_EMPLOYER_FORM:
      return { ...state, employerForm: action.payload };
    case SET_WIST_FORM:
      return { ...state, wistForm: action.payload };
    case SET_CCT_FORM:
      return { ...state, cctForm: action.payload };
    case SET_EPA_FORM:
      return { ...state, epaForm: action.payload };
    case SET_CITIZENSHIP_FORM:
      return { ...state, citizenshipForm: action.payload };
    case SET_SURVEY_FORM:
      return { ...state, surveyForm: action.payload };
    case SET_PUBLIC_KEY:
      return { ...state, public_key: action.payload };
    case SET_PRIVATE_KEY:
      return { ...state, private_key: action.payload };
    case SET_ICARE_LANGUAGE:
      return { ...state, selectedLanguage: action.payload };
    case SET_ICARE_LANGUAGE_ENUMS:
      return { ...state, selectedLanguageEnums: action.payload };
    case SET_SELECTED_INTAKE_FOR_ICARE:
      return { ...state, selectedIntakeForIcare: action.payload };
    case SET_ICARE_FORM:
      return { ...state, selectedIcareForm: action.payload };
    case SET_SELECTED_COMMUNITY_CONNECTION_INTAKE:
      return { ...state, communityConnectionIntakeForms: action.payload };
    case SET_CMCN_GROUP_DATA:
      return { ...state, selectedCMCNGroup: action.payload };
    case SET_CMCN_GROUPS:
      return { ...state, icareCMCNGroups: action.payload };
    case SET_ICARE_LOADER:
      return { ...state, showIcareLoader: action.payload };
    default:
      return state;
  }
};
